.auth-form {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px #00000063;
    background-color: #fff;
    max-width: 600px;
    margin: 0 auto;
    color: #000;
}

.auth-form h2 {
    margin-bottom: 20px;
    text-align: center;
}

.auth-form input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #1c1c1c;
    border-radius: 4px;
}

.auth-form a {
    color: #a80094;
}

.auth-form button {
    padding: 10px;
    font-size: 16px;
    background-color: #6c005c;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.auth-form button:hover {
    background-color: #a7008f;
}
