.root-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #fff;
    color: #1c1c1c;
}

.logo {
    height: 60px; /* Adjust the height as needed */
}


.app-container {
    display: flex;
    height: 100%;
    /* margin-top: 60px; */
}

.wa-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.forms-container {
    display: flex;
    justify-content: center;
    align-items: normal;
    width: 100%;
    height: 100vh;
}


.content {
    margin-top: 60px; /* Adjust based on TopNavBar height */
    flex-grow: 1;
    overflow-y: auto;
}

.form-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #1c1c1c;
}

.wa-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
}

.wa-form h2 {
    margin-bottom: 20px;
    text-align: center;
}

.wa-form input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
}

.wa-form button {
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}

.wa-form button:hover {
    background-color: #a7008f;
}




.displayInlineBlock {
    display: inline-block;
}
