.topnav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
}
.topnav-bar-logo {
  cursor: pointer;
}

.topnav-bar-logo {
  /* height: 40px; */
  margin-right: 10px;
}

/* .logo-text {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 5px;
} */

.user-info {
  display: flex;
  align-items: center;
}

.user-email {
  margin-right: 10px;
}

.logout-button {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}
